import './App.scss';
import Main from './components/main/Main';

function App() {
  return (    
    <Main />    
  );
}

export default App;
